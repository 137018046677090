import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "UserListInfoView"
};
import { ref, toRaw } from "vue";
import Apis_User from "@/apis/Apis_User";
import CacheUserInfoLib from "@/utils/Cache/CacheUserInfo";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Common_Obj from "@/utils/Common/Common_Obj";
export default {
  __name: 'UserList-Info',
  setup(__props, {
    expose
  }) {
    let show = ref(false);
    let UserData = ref([]);
    let GetUserDataRes = {};
    let UserId = -1;
    const CacheUserInfo = CacheUserInfoLib.getUserInfo();
    const OpenUserInfoView = userid => {
      UserId = userid;
      Apis_User.getUserData(userid).then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        GetUserDataRes = Common_Obj.DeepClone(res);
        let data = res.data;
        data.status = res.data['status'].toString();
        MakeFormData(data);
        show.value = true;
      });
    };
    expose({
      OpenUserInfoView
    });
    let Form = ref([]);
    let TextField = ['password', 'name', 'nickname', 'contact'];
    let FieldLabel = {
      username: '账号',
      password: '密码',
      agent: '所属',
      name: '名字',
      nickname: '备注',
      contact: '联系',
      money_int: '余额',
      money_time: '到期',
      login_time: '登录',
      reg_time: '注册'
    };
    const MakeFormData = dataArr => {
      if ((dataArr === null || dataArr === void 0 ? void 0 : dataArr.CutStatus) === 2) {
        dataArr['money_time'] = '未使用';
      }
      UserData.value = dataArr;
      Form.value.length = 0;
      let moneyField = CacheUserInfo.version !== 1 ? 'money_int' : 'money_time';
      for (let dataArrKey in dataArr) {
        if ([moneyField, 'id', 'ip', 'status', 'download_max', 'version', 'CutStatus'].includes(dataArrKey)) {
          continue;
        }
        let field = dataArrKey;
        Form.value.push({
          field,
          isRead: !TextField.includes(field),
          label: FieldLabel[field] || '???'
        });
      }
    };
    const confirm = () => {
      let resData = GetUserDataRes.data;
      let postData = toRaw(UserData.value);
      let bool = Common_Obj.isEqual(postData, resData);
      if (bool) {
        VantMsgLib.alert('未做修改,无需提交');
        return;
      }
      Apis_User.setUserInfo(UserId, postData);
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_radio = _resolveComponent("van-radio");
      const _component_van_radio_group = _resolveComponent("van-radio-group");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_dialog = _resolveComponent("van-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_dialog, {
        show: _unref(show),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(show) ? show.value = $event : show = $event),
        title: `UserId:${_unref(UserId)}`,
        "show-cancel-button": "",
        onConfirm: confirm
      }, {
        default: _withCtx(() => [_createVNode(_component_van_form, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(Form), item => {
            return _openBlock(), _createBlock(_component_van_cell_group, {
              inset: "",
              key: item.field
            }, {
              default: _withCtx(() => [_createVNode(_component_van_field, {
                modelValue: _unref(UserData)[item.field],
                "onUpdate:modelValue": $event => _unref(UserData)[item.field] = $event,
                label: item.label,
                disabled: item.isRead
              }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disabled"])]),
              _: 2
            }, 1024);
          }), 128)), _createVNode(_component_van_field, {
            name: "radio",
            label: "状态"
          }, {
            input: _withCtx(() => [_createVNode(_component_van_radio_group, {
              modelValue: _unref(UserData).status,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(UserData).status = $event),
              direction: "horizontal"
            }, {
              default: _withCtx(() => [_createVNode(_component_van_radio, {
                name: "1"
              }, {
                default: _withCtx(() => [_createTextVNode("启用")]),
                _: 1
              }), _createVNode(_component_van_radio, {
                name: "0"
              }, {
                default: _withCtx(() => [_createTextVNode("禁用")]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["show", "title"])]);
    };
  }
};