/**
 * 筛选函数
 */
import Common_Str from "@/utils/Common/Common_Str";
import Common_Time from "@/utils/Common/Common_Time";

const TableFilterHelper = {
    FilterArr(dataArr,filterActions){
        let bool = false;
        let resArr = [];
        for (let dataArrKey in dataArr) {
            let data = dataArr[dataArrKey];
            for (let filterActionsKey in filterActions) {
                bool = false;
                let filter = filterActions[filterActionsKey];
                if (filter.isTime){
                    bool = this.checkTime(data, filter.field, filter.attach);
                }else{
                    bool = this.searchStr(
                        data,
                        filter.field,
                        filter.value
                    );
                }
                if (bool){
                    resArr.push(data);
                    break;
                }
            }
        }
        return resArr;
    },

    /**
     * 查找对象指定属性的字符串
     * @param data 对象
     * @param field 属性名
     * @param str 查找的字符串
     * @return {boolean}
     * 存在返回true
     */
    searchStr(data,field,str){
        let res = data[field];
        if (!res || !str){
            return false;
        }
        let bool = Common_Str.checkExistString(res,str);
        return bool;
    },
    checkTime(data,field,timeArr){
        let res = data[field];
        if (!res || !Array.isArray(timeArr)){
            return false;
        }
        if (timeArr.length != 2){return false;}
        let int = Common_Time.TimeToTimestamp(res);
        let bool = Common_Time.isDuringDate(int,timeArr[0],timeArr[1]);
        return bool;
    }
};
export default TableFilterHelper;