import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import UserListInfo from "@/views/User/UserList/UserList-Info";
import UserListChangeUserMoney from "@/views/User/UserList/UserList-Tools/UserList-ChangeUserMoney";
import Common_Router from "@/utils/Common/Common_Router";
export default {
  __name: 'UserList-Tools',
  setup(__props, {
    expose
  }) {
    const actions = [{
      name: '编辑信息',
      type: 1
    }, {
      name: '充值余额',
      type: 2
    }, {
      name: '回收余额',
      type: 3
    }, {
      name: '查询下载',
      type: 4
    }, {
      name: '充值记录',
      type: 5
    }];
    const show = ref(false);
    let UserInfo = ref({
      id: -1,
      user: 'UserNull?'
    });
    const OpenToolsView = (id, user) => {
      UserInfo.value = {
        id,
        user
      };
      show.value = true;
      // console.log('OpenToolsView',UserInfo.value)
    };

    expose({
      OpenToolsView
    });
    const refsUserListInfo = ref();
    const refsUserListChangeUserMoney = ref();
    const onSelect = item => {
      show.value = false;
      switch (item.type) {
        case 1:
          //编辑信息
          refsUserListInfo.value.OpenUserInfoView(UserInfo.value.id);
          break;
        case 2:
          //充值余额
          refsUserListChangeUserMoney.value.OpenView(1);
          break;
        case 3:
          //回收余额
          refsUserListChangeUserMoney.value.OpenView(2);
          break;
        case 4:
          //查询下载
          Common_Router.push({
            name: 'User.UserDownLoadLog',
            params: {
              UserName: UserInfo.value.user
            }
          });
          break;
        case 5:
          //充值记录
          Common_Router.push({
            name: 'User.UserUseKeyLog',
            params: {
              UserName: UserInfo.value.user
            }
          });
          break;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => show.value = $event),
        actions: actions,
        onSelect: onSelect
      }, null, 8, ["show"]), _createVNode(_unref(UserListInfo), {
        ref_key: "refsUserListInfo",
        ref: refsUserListInfo
      }, null, 512), _createVNode(_unref(UserListChangeUserMoney), {
        ref_key: "refsUserListChangeUserMoney",
        ref: refsUserListChangeUserMoney,
        UserInfo: _unref(UserInfo)
      }, null, 8, ["UserInfo"])], 64);
    };
  }
};