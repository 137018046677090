import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import TableSetList from "@/components/Table/Table-SetList";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import TableFilter from "@/components/Table/Table-Filter";
import Common_Router from "@/utils/Common/Common_Router";
export default {
  __name: 'UserList-Menu',
  props: ['UserListArr'],
  emits: ['MenuEvent'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;

    //定义[输入框(文本)]类型的筛选器
    const FilterTextActions = [{
      name: '账号',
      field: 'username',
      icon: 'friends-o'
    }, {
      name: '名字',
      field: 'name',
      icon: 'label-o'
    }, {
      name: '备注',
      field: 'nickname',
      icon: 'chat-o'
    }];
    //定义[日期选择器(时间)]类型的筛选器
    const FilterTimeConfig = {
      name: '注册时间',
      field: 'reg_time',
      icon: 'exchange'
    };
    const show = ref(false);
    const actions = [{
      name: '查看列表',
      type: 1
    }, {
      name: '清空列表',
      type: 2
    }, {
      name: '筛选用户',
      type: 3
    }, {
      name: '回收用户',
      type: 4
    }, {
      name: '迁移用户',
      type: 5
    }, {
      name: '批量充值',
      type: 6
    }, {
      name: '注册日志',
      type: 7
    }, {
      name: '批量注册',
      type: 8
    }];
    const refsTableSetList = ref();
    const refsTableFilter = ref();
    const CacheField = 'UserList';
    const onSelect = item => {
      switch (item.type) {
        case 1:
          //编辑待操作的用户
          refsTableSetList.value.OpenView();
          break;
        case 2:
          //清空待操作的用户
          CacheData.SaveData(CacheField, []);
          VantMsgLib.alertSuccess();
          break;
        case 3:
          //筛选用户
          // refsTableFilter.value.OpenFilter();
          OpenFilterUserView();
          break;
        case 4:
          //回收用户
          Common_Router.push({
            name: 'User.RecoveryUser'
          });
          break;
        case 5:
          //迁移用户
          Common_Router.push({
            name: 'User.MoverUser'
          });
          break;
        case 6:
          //批量充值
          Common_Router.push({
            name: 'User.BatchRecharge'
          });
          break;
        case 7:
          //注册日志
          Common_Router.push({
            name: 'User.BatchRegUserLog'
          });
          break;
        case 8:
          //批量注册
          Common_Router.push({
            name: 'User.BatchRegUser'
          });
          break;
      }
    };

    /**
     * 向父组件传递消息
     * @param type [消息类型] 1.已筛选完成数据
     * @param data [对象]附带数据
     * @constructor
     */
    const SendMsg = (type, data = null) => {
      let res = {
        type,
        data
      };
      emits('MenuEvent', res);
    };
    const OpenFilterUserView = () => {
      refsTableFilter.value.OpenFilter();
    };
    expose({
      show,
      SendMsg,
      OpenFilterUserView
    });
    return (_ctx, _cache) => {
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => show.value = $event),
        actions: actions,
        "cancel-text": "取消",
        "close-on-click-action": "",
        onSelect: onSelect
      }, null, 8, ["show"]), _createVNode(_unref(TableSetList), {
        ref_key: "refsTableSetList",
        ref: refsTableSetList,
        field: CacheField
      }, null, 512), _createVNode(_unref(TableFilter), {
        ref_key: "refsTableFilter",
        ref: refsTableFilter,
        TextFormActions: FilterTextActions,
        TimeFormConfig: FilterTimeConfig,
        FilterArr: props.UserListArr,
        onUpdateFilterData: _cache[1] || (_cache[1] = arr => SendMsg(1, arr))
      }, null, 8, ["FilterArr"])], 64);
    };
  }
};