const Common_Time = {

    /**
     * 时间转时间戳
     * @param Time 待转换的时间
     * @return {number}
     * @constructor
     */
    TimeToTimestamp(Time){
        let date = new Date(Time);
        return date.getTime();
    },

    /**
     * 格式化时间
     * @param Time
     * @return {string}
     * 返回时间格式:2023/1/16 0:0:0
     */
    formatDate(Time){
        Time = new Date(Time);
        let str = `${Time.getFullYear ()}/${Time.getMonth() + 1}/${Time.getDate()}`;
        str += ` ${Time.getHours()}:${Time.getMinutes()}:${Time.getSeconds()}`;
        return str;
    },

    /**
     * [基于时间戳]判断是否在时间范围内
     * @param TimeStamp 待判断的时间戳
     * @param Start 起始时间戳
     * @param End 结束时间戳
     * @return {boolean}
     * 符合返回True
     */
    isDuringDate(TimeStamp,Start,End){
        let bool =
            TimeStamp > Start
        &&
            TimeStamp < End
        ;
        return bool;
    }
};
export default Common_Time