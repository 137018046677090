import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  style: {
    "margin": "16px"
  }
};
const _hoisted_2 = {
  style: {
    "margin": "16px"
  }
};
import { ref, toRaw } from "vue";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Common_Time from "@/utils/Common/Common_Time";
import TableFilterHelper from "@/components/Table/Table/TableFilterHelper";
import LangCom from "@/utils/Lang/LangCom";
export default {
  __name: 'Table-Filter',
  props: ['TextFormActions', 'TimeFormConfig', 'FilterArr'],
  emits: ['UpdateFilterData'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;
    const LangLib = new LangCom('Table', 'TableFilter');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);

    /**
     * ['TextFormActions','TimeFormConfig']解释:
     * 前者为数组对象类型,用于生成搜索字符串的筛选器
     * 后缀为单个对象,用于生成筛选时间范围的筛选器
     * 对象必须存在以下属性{name,field,icon}
     * ['FilterArr']解释:
     * 待筛选的数据数组
     */

    let form = ref([]);
    let isInit = false;
    let show = ref(false);
    /**
     * 打开筛选器,并初始化表单
     * @constructor
     */
    const OpenFilter = () => {
      if (!isInit) {
        for (let propsKey in props.TextFormActions) {
          let temp = props.TextFormActions[propsKey];
          let {
            name,
            field,
            icon
          } = temp;
          form.value.push({
            value: null,
            name,
            field,
            icon
          });
        }
        if (props.TimeFormConfig) {
          form.value.push({
            ...props.TimeFormConfig,
            isTime: true,
            attach: null
          });
        }
        isInit = true;
      } else {
        for (let formKey in form.value) {
          form.value[formKey]['value'] = null;
          if (form.value[formKey]['isTime']) {
            form.value[formKey]['attach'] = null;
          }
        }
      }
      show.value = true;
      showPicker.value = false;
    };
    expose({
      OpenFilter
    });

    //执行筛选
    let showPicker = ref(false); //日期选择器是否显示
    const onSubmit = () => {
      console.log('form', form.value);
      //检查是否已设置筛选条件
      let filter = toRaw(form.value);
      let isNull = true;
      for (let filterKey in filter) {
        let temp = filter[filterKey];
        if (temp.value) {
          isNull = false;
        }
      }
      if (isNull) {
        VantMsgLib.alert(GetLangSetup('onSubmit.error.filter'));
        return;
      }
      let res = TableFilterHelper.FilterArr(toRaw(props.FilterArr), toRaw(form.value));
      emits('UpdateFilterData', res);
      show.value = false;
      showPicker.value = false;
      VantMsgLib.notify(2, GetLangSetup('onSubmit.success') + res.length);
    };

    //[日期选择器]时间变动
    const onConfirm = date => {
      if (!Array.isArray(date)) {
        VantMsgLib.alert(GetLangSetup('onConfirm.error.arrTime'), 'Error');
        return;
      }
      let setError = true;
      let [startTime, endTime] = [Common_Time.formatDate(date[0]), Common_Time.formatDate(date[1])];
      let [startInt, endInt] = [Common_Time.TimeToTimestamp(date[0]), Common_Time.TimeToTimestamp(date[1])];
      for (let formKey in form.value) {
        let temp = form.value[formKey];
        if (temp.isTime) {
          form.value[formKey].attach = [startInt, endInt];
          form.value[formKey].value = [startTime, endTime].toString();
          setError = false;
          break;
        }
      }
      showPicker.value = false;
      if (setError) {
        VantMsgLib.alert(GetLangSetup('onConfirm.error.time'), 'Error');
      }
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_calendar = _resolveComponent("van-calendar");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: _unref(show),
        "onUpdate:show": _cache[3] || (_cache[3] = $event => _isRef(show) ? show.value = $event : show = $event),
        position: "center",
        style: {
          height: '80%',
          width: '100%'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_form, {
          onSubmit: onSubmit,
          style: {
            "margin-top": "10px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(form), (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              key: item.type
            }, [_createVNode(_component_van_cell_group, {
              inset: ""
            }, {
              default: _withCtx(() => {
                var _item$icon, _item$icon2;
                return [!item.isTime ? (_openBlock(), _createBlock(_component_van_field, {
                  key: item.type,
                  label: item.name,
                  name: item.name,
                  modelValue: _unref(form)[index].value,
                  "onUpdate:modelValue": $event => _unref(form)[index].value = $event,
                  "left-icon": (_item$icon = item.icon) !== null && _item$icon !== void 0 ? _item$icon : 'comment-o'
                }, null, 8, ["label", "name", "modelValue", "onUpdate:modelValue", "left-icon"])) : (_openBlock(), _createBlock(_component_van_field, {
                  key: item.type,
                  label: item.name,
                  name: item.name,
                  modelValue: _unref(form)[index].value,
                  "onUpdate:modelValue": $event => _unref(form)[index].value = $event,
                  "left-icon": (_item$icon2 = item.icon) !== null && _item$icon2 !== void 0 ? _item$icon2 : 'comment-o',
                  "is-link": "",
                  readonly: "",
                  onClick: _cache[0] || (_cache[0] = $event => _isRef(showPicker) ? showPicker.value = true : showPicker = true)
                }, null, 8, ["label", "name", "modelValue", "onUpdate:modelValue", "left-icon"])), _createVNode(_component_van_calendar, {
                  show: _unref(showPicker),
                  "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(showPicker) ? showPicker.value = $event : showPicker = $event),
                  onConfirm: onConfirm,
                  type: "range",
                  "min-date": new Date(2020, 0, 1)
                }, null, 8, ["show", "min-date"])];
              }),
              _: 2
            }, 1024)]);
          }), 128)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_button, {
            block: "",
            type: "primary",
            "native-type": "submit"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('submit')), 1)]),
            _: 1
          })]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
            block: "",
            type: "warning",
            onClick: _cache[2] || (_cache[2] = $event => {
              _isRef(show) ? show.value = false : show = false;
            })
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('quit')), 1)]),
            _: 1
          })])]),
          _: 1
        })]),
        _: 1
      }, 8, ["show"]);
    };
  }
};