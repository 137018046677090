import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-85870640"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "UserListView"
};
import Apis_User from "@/apis/Apis_User";
import { onMounted, provide, ref } from "vue";
import TableXsr from "@/components/Table/Table-User";
import UserListMenu from "@/views/User/UserList/UserList-Menu";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Common_Obj from "@/utils/Common/Common_Obj";
import UserListTools from "@/views/User/UserList/UserList-Tools";

//获取用户信息代码块

export default {
  __name: 'UserList',
  setup(__props) {
    let userDataType = 2; //[获取用户类型]1=包含下级代理用户 2=只显示自己用户
    const userListArr = ref([]);
    const userNameAndIdArr = ref([]); //[数组对象]每个对象包含(用户名与用户id)
    const getUserList = () => {
      Apis_User.getUserList(userDataType).then(res => {
        var _res$data;
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        userListArr.value = (_res$data = res.data) !== null && _res$data !== void 0 ? _res$data : [];
        userNameAndIdArr.value = [];
        for (let dataKey in res.data) {
          var _res$data$dataKey$id, _res$data$dataKey$use;
          userNameAndIdArr.value.push({
            id: (_res$data$dataKey$id = res.data[dataKey].id) !== null && _res$data$dataKey$id !== void 0 ? _res$data$dataKey$id : -1,
            username: (_res$data$dataKey$use = res.data[dataKey].username) !== null && _res$data$dataKey$use !== void 0 ? _res$data$dataKey$use : ''
          });
        }
        VantMsgLib.notify(2, '已加载用户数量:' + res.data.length);
      });
    };
    onMounted(() => {
      setTimeout(() => {
        getUserList();
      }, 0);
    });
    provide('SearchIcon', true); //通知表格组件开启右上角放大镜图标(筛选功能)[Table-Header.Vue]
    /**
     * 接收表格组件传来的事件[单击表项,选中,...等]
     * @param options [对象]type:事件类型 data:数据 attach:附带数据/备注/..
     * @constructor
     */
    const TableEvent = options => {
      // console.log('TableEvent',options);
      // [事件类型]1.工具栏 2.选中用户变动 3.点击某个表项 4.点击了放大镜图标[筛选/搜索]
      switch (options.type) {
        case 1:
          refsUserListMenu.value.show = true;
          break;
        case 2:
          {
            //生成通用列表数据
            CacheData.SaveData('UserList', options.data);
            //生成[用户.批量充值]数据
            let temp = Common_Obj.DeepClone(options.data);
            let arr = temp.map(item => {
              item.username += '----';
              return item;
            });
            CacheData.SaveData('UserList-BatchRecharge', arr);
            VantMsgLib.alertSuccess();
            break;
          }
        case 3:
          // refsUserListInfo.value.OpenUserInfoView(options.data.id)
          refsUserListTools.value.OpenToolsView(options.data.id, options.data.username);
          break;
        case 4:
          refsUserListMenu.value.OpenFilterUserView();
          break;
      }
    };

    /**
     * [UserListMenu]接收菜单传来的消息
     * @param msg
     * @constructor
     */
    const MenuEvent = msg => {
      console.log('MenuEvent', msg);
      if (msg.type === 1) {
        var _msg$data;
        let arr = (_msg$data = msg.data) !== null && _msg$data !== void 0 ? _msg$data : [];
        let res = arr.map(item => {
          return {
            username: item.username,
            id: item.id
          };
        });
        userNameAndIdArr.value = res;
        if (res.length <= 0) {
          VantMsgLib.alert('搜索结果为空');
        }
      }
    };
    const refsUserListMenu = ref();
    const refsUserListTools = ref();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(TableXsr), {
        ViewData: userNameAndIdArr.value,
        HeaderTitle: "用户管理",
        onTableEvent: TableEvent
      }, null, 8, ["ViewData"]), _createVNode(_unref(UserListMenu), {
        ref_key: "refsUserListMenu",
        ref: refsUserListMenu,
        UserListArr: userListArr.value,
        onMenuEvent: MenuEvent
      }, null, 8, ["UserListArr"]), _createVNode(_unref(UserListTools), {
        ref_key: "refsUserListTools",
        ref: refsUserListTools
      }, null, 512)]);
    };
  }
};